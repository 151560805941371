/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  BoxIcon,
  CalendarIcon,
  CaretDownIcon,
  LockIcon,
  PersonIcon,
  UnLockIcon,
} from '../icon';
import './../style.css';
import { AddPersonnelComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { ChartDataProps, EmployeeProps } from '../../../includes/types';
import { GetRequest, PostRequest } from '../../../includes/functions';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { CONSTANTS } from '../../../includes/constant';
import { BaseButton } from '../../../components/buttons';
import { CalendarComponent } from '../../../components/Calender';
import { BaseLoader } from '../../../components/baseloader';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function DashboardSection() {
  const tableFilter: string[] = ['Combined', 'Check-Ins', 'Check-Outs'];
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'months').toISOString()
  );
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [showAddPersonnel, setShowAddPersonnel] = useState<boolean>(false);
  const [showImportPersonnel, setShowImportPersonnel] =
    useState<boolean>(false);
  const [totalEmployees, setTotalEmployees] = useState<number>(0);
  const [totalCheckIn, setTotalCheckIn] = useState<number>(0);
  const [totalCheckOut, setTotalCheckOut] = useState<number>(0);
  const tabs = [
    { class: 'yellow-card', title: 'Total Employees', amount: totalEmployees },
    { class: 'black-card', title: 'Total Check-ins', amount: totalCheckIn },
    { class: 'gray-card', title: 'Total Check-outs', amount: totalCheckOut },
  ];
  const [chartCheckInData, setchartCheckInData] = useState<ChartDataProps>({
    data: [],
    labels: [],
  });
  const [chartCheckOutData, setchartCheckOutData] = useState<ChartDataProps>({
    data: [],
    labels: [],
  });

  const [selectedTab, setSelectedTab] = useState<string>(tableFilter[0]);

  const TotalCheckOuts = async () => {
    try {
      const res = await GetRequest('trip/check-out', {}, false);
      if (res.status && res.data) {
        setTotalCheckOut(res.data.totalCheckOuts);
      } else {
        console.error('Error: No data or status is false', res);
      }
    } catch (error) {
      console.error('Error fetching check-outs:', error);
    }
  };

  const TotalCheckIns = async () => {
    try {
      const res = await GetRequest('trip/check-in', {}, false);
      if (res.status && res.data) {
        setTotalCheckIn(res.data.totalCheckIns);
      } else {
        console.error('No check-ins found', res);
      }
    } catch (error) {
      console.error('Error fetching check-ins:', error);
    }
  };

  const AllEmployees = async () => {
    try {
      const res = await GetRequest('employees', {}, false);
      if (res.status && Array.isArray(res.data.employees)) {
        setTotalEmployees(res.data.employees.length);
      } else {
        console.error('Unexpected response format', res);
      }
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  const AllChartData = () => {
    setSearching(true);
    GetRequest(
      'admin/attendence/report',
      {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      },
      false
    ).then((res) => {
      setSearching(false);
      var selectMonths: string[] = [];
      var countIntervalsCheckIn: number[] = [];
      var countIntervalsCheckOut: number[] = [];
      if (res.status) {
        console.log('API to get attendance that i know not of', res);
        res.data.forEach((a: any, i: number) => {
          const month = moment(a.checkIns[0]).format('MMM');
          selectMonths.push(month);
          countIntervalsCheckIn.push(a.checkIns.length);
          countIntervalsCheckIn.push(100);
          countIntervalsCheckOut.push(a.checkOuts.length);
          countIntervalsCheckOut.push(100);
        });
        setchartCheckInData({
          data: countIntervalsCheckIn,
          labels: selectMonths,
        });
        setchartCheckOutData({
          data: countIntervalsCheckOut,
          labels: selectMonths,
        });
      } else {
        const month = moment(startDate).format('MMM');
        setchartCheckInData({
          data: [0, 100],
          labels: [month],
        });
        setchartCheckOutData({
          data: [0, 100],
          labels: [month],
        });
      }
    });
  };

  const handleSelect = (date: Date) => {
    console.log(date); // native Date object
  };

  useEffect(() => {
    TotalCheckOuts();
    TotalCheckIns();
    AllChartData();
    AllEmployees();
    thisView.current?.addEventListener('mouseleave', () => {
      setShowCalendar(false);
    });
  }, [startDate, endDate]);

  const thisView = useRef() as RefObject<HTMLDivElement>;

  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="row">
        <div className="col-8">
          <div className="title-text">Welcome back 😊</div>
          <div className="">Let's pick things up from where you left it</div>
        </div>
        <div className="col-4">
          <div ref={thisView} className="position-relative">
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
            >
              <div className="bx">
                {searching ? <BaseLoader /> : <CalendarIcon />}
                <span className="tx">
                  {moment(startDate).format('MMM DD, YYYY')} -{' '}
                  {moment(endDate).format('MMM DD, YYYY')}
                </span>
                <span className="caret">
                  <CaretDownIcon />
                </span>
              </div>
            </div>
            {showCalendar && <CalendarComponent
                onClose={() => {
                  setShowCalendar(false);
                }}
                startDate={moment().subtract(3, 'M').toISOString()}
                onValue={({ startDate, endDate }) => {
                  setEndDate(endDate);
                  setStartDate(startDate);
                  setTimeout(() => {
                    AllChartData();
                  }, 500);
                  setShowCalendar(false);
                }}
              />}
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-3">
        <div className="col-8">
          <div
            className="card"
            style={{ minHeight: 370, position: 'relative' }}
          >
            <div className="row p-3">
              <div className="col-4">
                <div className="chart-title">TREEPZ HISTORY</div>
                <div className="chart-date">
                  {moment(startDate).format('MMM DD, YYYY')} -{' '}
                  {moment(endDate).format('MMM DD, YYYY')}
                </div>
              </div>
              <div className="col-8 pe-3">
                <div className="chart-tabs-container row">
                  {tableFilter.map((a, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedTab(a);
                      }}
                      className={`col-4 ${
                        selectedTab === a ? 'chart-tabs-active' : ''
                      }`}
                    >
                      {a}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="px-3"
              style={{ position: 'relative', height: 360, overflow: 'hidden' }}
            >
              {selectedTab === 'Check-Ins' || selectedTab === 'Combined' ? (
                <div style={{ height: '100%' }}>
                  <Bar
                    data={{
                      labels: chartCheckInData.labels,
                      datasets: [
                        {
                          label: '',
                          data: chartCheckInData.data,
                          backgroundColor: '#F8B02B',
                          maxBarThickness: 30,
                        },
                      ],
                    }}
                    color="red"
                  />
                </div>
              ) : null}
              {selectedTab === 'Check-Outs' || selectedTab === 'Combined' ? (
                <div
                  style={{
                    position: 'absolute',
                    left: 40,
                    top: -18,
                    width: 580,
                    height: 370,
                  }}
                >
                  <Bar
                    options={{
                      scales: {
                        x: {
                          display: selectedTab === 'Check-Outs' ? true : false,
                        },
                        y: {
                          display: selectedTab === 'Check-Outs' ? true : false,
                        },
                      },
                    }}
                    data={{
                      labels: chartCheckOutData.labels,
                      datasets: [
                        {
                          label: '',
                          data: chartCheckOutData.data,
                          backgroundColor: '#3B6FE9',
                          maxBarThickness: 20,
                        },
                      ],
                    }}
                    color="red"
                  />
                </div>
              ) : null}
            </div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                position: 'absolute',
                bottom: 10,
                left: 0,
                zIndex: 999999,
                width: '100%',
              }}
            >
              <div className="c-box-checkin"></div>
              <span className="px-2 cb-txt">Check-ins</span>
              <div className="c-box-checkout"></div>
              <span className="px-2 cb-txt">Check-outs</span>
            </div>
          </div>
        </div>

        <div className="col-4">
          {tabs.map((a, i) => (
            <div key={i} className={`card dx-card mb-3 ${a.class}`}>
              <div className="row ps-3">
                <div className="col-8 p-3">
                  <div className="t1">{a.title}</div>
                  <div className="t2">{a.amount}</div>
                  <div className="d-flex">
                    <span className="gt">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 12L12 4M12 4H6.66667M12 4V9.33333"
                          stroke="#3CC13B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      0%
                    </span>
                    {/* <span className="ps-2 text-2">From last month</span> */}
                  </div>
                </div>
                <div className="col-4 p-3 position-relative">
                  <div className="circle">
                    {i === 0 && <PersonIcon />}
                    {i === 1 && <LockIcon />}
                    {i === 2 && <UnLockIcon />}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TableSection 
      searchData={{
        startDate,
        endDate
      }}
      />
      {showAddPersonnel && (
        <AddPersonnelComponent onClose={() => setShowAddPersonnel(false)} />
      )}
      {showImportPersonnel && (
        <ImportPersonnelComponent
          onClose={() => setShowImportPersonnel(false)}
        />
      )}
    </div>
  );
}
interface TableSectionProp {
  searchData?:{
    startDate:string;
    endDate:string;
  }
}
const TableSection = (prop:TableSectionProp) => {
  const [historyList, setHistoryList] = useState<EmployeeProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
 
  const GetTripsHistory = (page: number) => {
    setLoading(true);
    GetRequest(`trips?from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(endDate).format("YYYY-MM-DD")}&itemsPerPage=${pageSize}&pageNumber=${page}`,{}, false).then((res) => {
        setLoading(false);
        console.log('API trip history', res)
        if (res.status && Array.isArray(res.data.trips)) {
          const employeeHistory = res.data.trips.map((trip: any) => ({
            id: trip._id,
            name: `${trip.rider.firstName} ${trip.rider.lastName}`,
            totalCheckIn: trip.checkInTime,
            totalCheckOut: trip.checkOutTime,
            startDate: trip.startDate || 'Date not available',
            vehicleLocation: trip.vehicleLocation,
            vehicleTag: trip.vehicle,
          }))
          console.log('Employee trips history', employeeHistory)
          setHistoryList(employeeHistory);
        } else {
          setHistoryList([]);
        }
      }).catch(error => {
        setLoading(false);
        console.log('Fetching employee trips:', error)
      });
  }

  useEffect(() => {
    GetTripsHistory(1);
  }, []);
  
  useEffect(() => {
  }, [prop.searchData]);
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <span className="table-title">Treepz History</span>
        <span
          className="bx ms-3 d-flex align-items-center justify-content-center"
          style={{ width: 90, height: 30 }}
        >
          <span style={{ fontSize: 14 }}>
            <NavLink to={CONSTANTS.Routes.TreepzHistory}>View all</NavLink>
          </span>
        </span>
      </div>

      {error && (
        <div className="text-center text-danger">
          <p>{error}</p>
        </div>
      )}

      {!loading && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">S. No.</th>
              <th scope="col">Employee ID</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Date</th>
              <th scope="col">Check-In</th>
              <th scope="col">Check-Out</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <BaseLoader /> <small className="fs-small">Fetching...</small>
                </td>
              </tr>
            )}

            {!loading && historyList.length === 0 && (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center' }}>
                  <BoxIcon />
                  <p>No recent treepz history.</p>
                </td>
              </tr>
            )}
            {historyList
              .filter((trips, i) => i < 5)
              .map((trip, i) => (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{trip.id}</td>
                  <td>{trip.name}</td>
                  <td>{moment(trip.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
                  {/* <td>{trip.email}</td> */}
                  <td>{moment(trip.totalCheckIn).format("DD-MM-YYYY hh:mm A")}</td>
                  <td>{moment(trip.totalCheckOut).format("DD-MM-YYYY hh:mm A")}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};
