/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EditIcon from "../../../../assets/icons/editIcon";
import TrashIcon from "../../../../assets/icons/trashIcon";
import { ConfirmDialogComponent } from "../../components/confirmDialog";
import { EditUserComponent } from "../../components/editUser";
import { Pagination } from "../../../../components/pagination";
import { GetRequest, PostRequest, PutEmployee } from "../../../../includes/functions";
import { EmployeeProps } from "../../../../includes/types";
import moment from "moment";
import { BaseLoader } from "../../../../components/baseloader";
import { BoxIcon } from "../../icon";
import { DownloadCSV } from "../../../../includes/createCSVFileHook";
import { toast } from "react-toastify";
import { WhiteButton } from "../../../../components/buttons";

interface EmployeesTableProps {
  searchText: string;
  onAddNewBusiness?: () => void;
}

const EmployeesTable = (props: EmployeesTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmDailog, setShowConfirmDailog] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [listOfEmployees, setListOfemployees] = useState<EmployeeProps[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeProps | null>(null);
  const [loading, setLoading] = useState(false);

  const GetEmployees = (page: number) => {
    setFetching(true);
    GetRequest(
      `employees`,
      {
        page: page,
        pageSize: pageSize,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      },
      false
    ).then((res) => {
      setFetching(false);
      if (res.status) {
        const employeesWithFullName = res.data.employees.map((employee: any) => ({
          id: employee._id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          email: employee.email,
          totalCheckIns: employee.totalCheckIns,
          totalCheckOuts: employee.totalCheckOuts,
          vehicleLocation: employee.vehicleLocation,
          vehicleTag: employee.vehicleTag,
        }));
        setListOfemployees(employeesWithFullName);
      }
    }).catch(error => {
      setFetching(false);
      toast.error("Failed to fetch employees.");
      console.error('Error fetching employees:', error);
    });
  };

  const DeleteUser = (id: any) => {
    return ;
    setLoading(true);
    PostRequest("delete:user", { id: id }, true).then((response) => {
      setLoading(false);
      setListOfemployees(listOfEmployees.filter((employee) => employee.id !== id));
      setShowConfirmDailog(false);
    }).catch(error => {
      setLoading(false);
    });
  };

  const UpdateUser = (values: { employeeId: string; firstName: string; lastName: string; email: string }) => {
    if (!values.employeeId) {
      console.error('No employeeId provided');
      toast.error("No employee ID provided.");
      return;
    }
    setUpdating(true);
    PutEmployee(values.employeeId, {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    })
      .then((response) => {
        setUpdating(false);
        if (response.status) {
          GetEmployees(currentPage);
          setShowEditUser(false);
          toast.success("Employee updated successfully.");
        }
      })
      .catch((error) => {
        setUpdating(false);
        toast.error("Failed to update employee.");
        console.error("Update failed:", error);
      });
  };

  const filterEmployees = (employees: EmployeeProps[], searchText: string) => {
    return employees.filter((employee) =>
      String(employee?.firstName).toLowerCase().includes(searchText.toLowerCase()) ||
    String(employee.lastName).toLowerCase().includes(searchText.toLowerCase()) 
    );
  };

  useEffect(() => {
    GetEmployees(currentPage);
    const handleReloadEmployeeTable = (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetEmployees(1);
        }, 1000);
      }
    };
    window.addEventListener("reloadEmployeeTable", handleReloadEmployeeTable);

    return () => {
      window.removeEventListener("reloadEmployeeTable", handleReloadEmployeeTable);
    };
  }, [currentPage]);

  const Alldata = filterEmployees(listOfEmployees, props.searchText);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Employee ID</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Email Address</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {fetching && (
            <tr>
              <td colSpan={10}>
                <BaseLoader /> <small className="fs-small">Fetching...</small>
              </td>
            </tr>
          )}
          {Alldata.length === 0 && !fetching && (
            <tr>
              <td colSpan={7}>
                <div className="text-center p-5">
                <BoxIcon />
                  <div className="fs-6 fw-bold my-3">No employee added yet</div>
                  <WhiteButton
                onClick={() => {
                  if(props.onAddNewBusiness)
                  {
                  props.onAddNewBusiness();
                  }
                }}
              >
                <span className="mx-2">Add new employee</span>
              </WhiteButton>
                </div>
              </td>
            </tr>
          )}
          {Alldata.map((employee, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{employee.id}</td>
              <td>{`${employee.firstName} ${String(employee?.lastName).replace("undefined","")}`}</td>
              <td>{employee.email}</td>
              <td>
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        setSelectedEmployee(employee);
                        setShowEditUser(true);
                      }}
                    >
                      <EditIcon />
                    </button>
                  </div>
                  {/* <div className="col-6">
                    <button
                      onClick={() => {
                        setSelectedEmployee(employee);
                        setShowConfirmDailog(true);
                      }}
                      className="btn"
                    >
                      <TrashIcon />
                    </button>
                  </div> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          GetEmployees(d);
          setCurrentPage(d);
        }}
      />

      {showConfirmDailog && (
        <ConfirmDialogComponent
          onClose={() => setShowConfirmDailog(false)}
          loading={loading}
          confirm={() => DeleteUser(selectedEmployee?.id)}
        />
      )}

      {showEditUser && (
        <EditUserComponent
          loading={updating}
          employeeData={selectedEmployee}
          onClose={() => setShowEditUser(false)}
          onValue={(id) => {
            GetEmployees(1);
            setShowEditUser(false);
          }}
        />
      )}
    </>
  );
};

export default EmployeesTable;
