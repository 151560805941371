/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect, useRef, useState } from 'react'
import './../style.css';
import { SearchBar } from '../components/searchBar';
import { AddPersonnelComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import { Menu } from './HistoryTables/invitations';
import { Pagination } from '../../../components/pagination';
import ThreeVerticalDotsIcon from '../../../assets/icons/threeDots';
import { GetRequest, PostRequest } from '../../../includes/functions';
import { EmployeeProps, HistoryProps } from '../../../includes/types';
import moment from 'moment';
import { DownloadIcon } from '../../../assets/icons/DownloadBtn';
import { BaseLoader } from '../../../components/baseloader';
import { toast } from 'react-toastify';
import { DownloadCSV } from '../../../includes/createCSVFileHook'

export default function TreepzHistorySection() {
  const [processing, setProcessing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showAddPersonnel, setShowAddPersonnel] = useState<boolean>(false)
  const [employeeDetails, setEmployeeDetails] = useState<HistoryProps | null>(null)
  const [filterString, setFilterString] = useState<string>("");
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false)
  const [historyList, setHistoryList] = useState<HistoryProps[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState(false);

  const HandleExport = () => {
    setProcessing(true);
    GetRequest("auth/export", {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    }, true).then((res) => {
      setProcessing(false);
    })
  }


  const handleExport = () => {
    DownloadCSV({ data: historyList, fileName: "history_list" });
    toast.success("Download was successful.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const GetTripsHistory = (page: number) => {
    setLoading(true);
    GetRequest(`trips?from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(endDate).format("YYYY-MM-DD")}&itemsPerPage=${pageSize}&pageNumber=${page}`,{}, false).then((res) => {
        setLoading(false);
        console.log('API trip history', res)
        if (res.status && Array.isArray(res.data.trips)) {
          const employeeHistory = res.data.trips.map((trip: any) => ({
            id: trip._id,
            name: `${trip.rider.firstName} ${trip.rider.lastName}`,
            totalCheckIn: trip.checkInTime,
            totalCheckOut: trip.checkOutTime,
            startDate: trip.startDate || 'Date not available',
            vehicleLocation: trip.vehicleLocation,
            vehicleTag: trip.vehicle,
          }))
          console.log('Employee trips history', employeeHistory)
          setHistoryList(employeeHistory);
        } else {
          setHistoryList([]);
        }
      }).catch(error => {
        setFetching(false);
        console.log('Fetching employee trips:', error)
      });
  }

  const DeleteUser = (id: string) => {
    setLoading(true);
    PostRequest("delete:auth/user", {
      id: id
    }).then((res) => {
      if (res.status) {
        setHistoryList(historyList.filter((a, i) => a.employeeId !== id));
      }
    })
  }

  
  const printedSection = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    GetTripsHistory(1);
  }, [])

  const PrintInfo = () => {
    if (printedSection.current) {
      var openWindow = window.open("", "title", "attributes");
      openWindow?.document.write(printedSection.current?.innerHTML);
      openWindow?.document.close();
      openWindow?.focus();
      openWindow?.print();
      openWindow?.close();
    }
  }

  const AllItems: HistoryProps[] = historyList.filter((trips, i) => String(trips.name).toLowerCase().includes(String(filterString).toLowerCase()));

  return <div style={{ position: "relative" }}>
    <div className='main-scrollable p-5 pt-0' >
      <div className="heading mb-3">
        <b className='fCap'>Treepz history</b>
      </div>
      <SearchBar
        onSearch={(d) => {
          setFilterString(d);
        }}
        page='history'
        onAddPersonnel={() => setShowAddPersonnel(true)}
        processing={processing}
        onExportPersonnel={() => {
          handleExport()
        }}
        onImportPersonnel={() => setShowImportPersonnel(true)}
        showFilter={true}
        onFilterValue={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
      />

      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">S. No.</th>
            <th scope="col">Employee ID</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Date</th>
            <th scope="col">Check-In</th>
            <th scope="col">Check-Out</th>
            {/* <th scope="col">Pick-up Location</th> */}
            {/* <th scope="col">Vehicle Location</th> */}
            {/* <th scope="col">Vehicle tag</th> */}
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {loading && <tr >
            <td colSpan={10}>
              <BaseLoader /> <small className='fs-small'>Fetching...</small>
            </td>
          </tr>}
          {AllItems.map((trip, i) =>
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{trip.id}</td>
              <td>{trip.name}</td>
              <td>{moment(trip.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
              {/* <td>{trip.email}</td> */}
              <td>{moment(trip.totalCheckIn).format("DD-MM-YYYY hh:mm A")}</td>
              <td>{moment(trip.totalCheckOut).format("DD-MM-YYYY hh:mm A")}</td>
              {/* <td>{trip.vehiclePickUpLocation}</td> */}
              <td>{trip.location}</td>
              {/* <td>{trip.vehicleTag}</td> */}
              <td style={{ width: 50 }}>
                <Menu
                  onValue={(value) => {
                    if (value.action === "delete") {
                      DeleteUser(trip.employeeId)
                    }
                    if (value.action === "view") {
                      setEmployeeDetails(trip)
                    }
                  }}
                  type='history'
                >
                  <ThreeVerticalDotsIcon />
                </Menu>
              </td>
            </tr>)}
        </tbody>
      </table>
      
      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          GetTripsHistory(d);
        }}
      />
      {showAddPersonnel && <AddPersonnelComponent
        onClose={() => setShowAddPersonnel(false)}
      />}
      {showImportPersonnel && <ImportPersonnelComponent
        onClose={() => setShowImportPersonnel(false)}
      />}
    </div>
    {employeeDetails && <div className='main-scrollable p-5 pt-3' style={{ position: "absolute", top: 0, left: 0, background: "white", height: "100%", width: "100%" }} >
      <div style={{ width: 200, paddingTop: 20, paddingLeft: 10, height: 60, top: 0, left: 360, backgroundColor: "white" }} >
        <b className='fCap'>Trip details</b>
      </div>
      <div className='row' >
        <div className='col-9' ref={printedSection}  >
          <div className='row pb-3' >
            <div className='col-4' >Employee ID</div>
            <div className='col-4' >{employeeDetails.id}</div>
          </div>

          <div className='row pb-3' >
            <div className='col-4' >Employee Name</div>
            <div className='col-4' >{employeeDetails.name}</div>
            <div className='col-4' ></div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Email Address</div>
            <div className='col-4' >{employeeDetails.email}</div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Date</div>
            <div className='col-4' >{employeeDetails.email}</div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Check-In Time</div>
            <div className='col-4' >{employeeDetails.totalCheckIn}</div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Check-Out Time</div>
            <div className='col-4' >{employeeDetails.totalCheckOut}</div>
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Pick-up Location</div>
            {/* <div className='col-4' >{employeeDetails.vehicleName}</div> */}
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Vehicle Location</div>
            {/* <div className='col-4' >{employeeDetails.vehicleLocation}</div> */}
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Vehicle Tag</div>
            <div className='col-4' >{employeeDetails.vehicleTag}</div>
          </div>
        </div>
        <div className='col-3' >
          <button className='gray-card btn'
            onClick={() => {
              PrintInfo()
            }}
          >
            <DownloadIcon />
            <span className='px-2 fw-bold fs-6' >Download receipt</span>
          </button>
        </div>
      </div>
    </div>}
  </div>
}
