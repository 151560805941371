import React, { FormEvent, useEffect, useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { Navigate, NavLink, useNavigate, useNavigation, useParams, useSearchParams} from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton, LightYellowButton } from '../../components/buttons'
import { Formik } from 'formik';
import * as y from 'yup';
import { GoBackIcon } from '../../assets/icons/BackIcon'
import { GetRequest, PostRequest } from '../../includes/functions'
import { SuccessComponent } from './success'
import { BaseLoader } from '../../components/baseloader'
const schema = y.object({
    password:y.string().required("Password is required."),
    roleInOrganization:y.string().required("RoleInOrganization is required"),
    terms:y.bool().oneOf([true], 'Accept our terms and conditions.').required("Accept our terms and conditions."),
    })
interface UserDataProp {
  roleInOrganization?:string;
  password?:string;
  terms?:boolean;
}
interface CompanyDetailsProp {
  name:string;
  email:string;
}
export default function CreatePasswordScreen() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [refOtp,setRefOtp] = useState<string>("");
  const [showSuccess,setShowSuccess] = useState(false);
  const [details,setDetails] = useState<CompanyDetailsProp>({
    name:"",
    email:"",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(()=>{
  const refParam = searchParams.get("ref");
  if(refParam)
  {
  setRefOtp(refParam);
GetRequest("business?ref="+refParam,{}).then((res)=>{
  console.log(res);
  if(res.status)
  {
    setDetails(res.data.business);
  }else{
    navigate("/");
  }
})
  }
  },[searchParams])
  return (<div className='row'>
     <div className='col-3 sidemenu position-relative' >
     <div className='p-5 ' >
        <Logo />
        <div className='sub'>
        Dare to move,<br/>keep the record
        </div>
        </div>
        <div className='logo-wrapper' >
        <LogoDesign />
        </div>
     </div>
     <div className='col-9 p-5' style={{overflow:"scroll",height:"100vh"}} >
     <Formik
        onSubmit={(values:UserDataProp) => {
          setLoading(true);
          PostRequest("auth/invite",{
            password:values.password,
            roleInOrganization:values.roleInOrganization,
            ref:refOtp,
            fullName:details.name
          },true).then((res)=>{
            setLoading(false);
            if(res.status)
            {
              navigate(CONSTANTS.Routes.Login,{replace:true});
            }
           })
        }}
        validationSchema={schema}
        initialValues={{
          password: '',
          roleInOrganization:"",
          terms:false
        }}
      >
     {({ handleSubmit, setFieldValue, values, errors }) =><div className='ps-5' >
        <div className="text-start title-text">Let's go!</div>
        <div className="text-start">Create a password to proceed.</div>
        <div className='row p-5 ps-0' >
        <div className='col-8 p-3' >
        <BaseInput 
        label='Full name'
        name='fullName'
        type='text'
        max={50}
        disabled
        placeholder='Full name'
        onValueChange={(d)=>{
          // setFieldValue("roleInOrganization",d.target.value);
        }}  
        value={details.name}
        />
        <BaseInput 
        label='Work email address'
        name='workEmail'
        type='text'
        max={50}
        disabled
        placeholder='Work email address'
        onValueChange={(d)=>{
          // setFieldValue("roleInOrganization",d.target.value);
        }}  
        value={details.email}
        />
        <BaseInput 
        label='Role in organization'
        name='workRole'
        type='select'
        max={50}
        placeholder='Human resources manager'
        onValueChange={(d)=>{
          setFieldValue("roleInOrganization",d.target.value);
        }}  
        value={values.roleInOrganization}
        error={errors.roleInOrganization}
        />
        
       <BaseInput 
       label='Password'
        name='Password'
        type='password'
        max={100}
        placeholder='Enter your password'
        onValueChange={(d)=>{
          setFieldValue("password",String(d.target.value).replace(/[ ]/g,''));
        }}  
        value={values.password}
        error={errors.password}
        />
        <div className="form-check">
        <input className="form-check-input" 
        required
        type="checkbox" 
        value={values.terms?1:0} 
        id="terms"
        onChange={(d)=>{
        setFieldValue("terms",!values.terms);
        }}
         />
        <label className="form-check-label" htmlFor="terms" >
        By checking the box, you agree to our  <a href={"https://www.treepz.com/terms"}>Terms of use & Privacy policy</a>
        </label>
        </div>
        <div className='error'>{errors.terms}</div>
        <div className='row p-2 pe-3' >
        <BaseButton 
        onClick={handleSubmit}
        loading={loading}
        style={{marginTop:30}}
        >Join</BaseButton>
        </div>
        
        
        </div>
        <div className='col-2' ></div>
        </div>
   </div>}
    </Formik>
        <span className='poweredby'>
            <PoweredByComponent />
        </span>
     </div>
    </div>
  )
}
