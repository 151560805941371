import React, { useEffect, useState } from "react";
import { BaseButton } from "../../../../components/buttons";
import BaseInput from "../../../../components/baseInput";
import { Formik } from 'formik';
import * as y from 'yup';
import { PostRequest, PutEmployee } from '../../../../includes/functions';
import { EmployeeProps } from "../../../../includes/types";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = y.object({
  email: y.string().required().email("A valid email is required.").max(150, "Email is too long."),
  employeeId: y.string().required("Employee Id is required."),
  firstName: y.string().required("First name is required.").max(25, "First name is too long."),
  lastName: y.string().required("Last name is required.").max(25, "Last name is too long."),
})

interface ConfirmDialogComponentProps {
  onClose: () => void;
  onValue: (v: any) => void;
  employeeData: EmployeeProps | null;
  loading?: boolean;
}

export const EditUserComponent = (props: ConfirmDialogComponentProps) => {
 const [loading,setLoading] = useState<boolean>(false);

  return (
    <div className="modall" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit user details</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={props.onClose}
            ></button>
          </div>
          <div className="modal-body p-3">
            <Formik
              onSubmit={(values) => {
                setLoading(true);
                PostRequest("put:employee/"+values.employeeId, {
                  firstName: values.firstName,
                  lastName: values.lastName,
                },true)
                  .then((response: { data: any }) => {
                    setLoading(false);
                    props.onValue(values);
                  })
              }}
              validationSchema={schema}
              initialValues={{
                employeeId: props.employeeData?.id?.toString() || "",
                firstName: props.employeeData?.firstName || "",
                lastName: props.employeeData?.lastName || "",
                email: props.employeeData?.email || "",
              }}
            >
              {({ handleSubmit, handleChange, errors, touched, values }) => (
                <div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-message">
                      Please fix the following errors: {JSON.stringify(errors)}
                    </div>
                  )}
                  <BaseInput
                    label="Employee's ID"
                    disabled={true}
                    name="employeeId"
                    type="text"
                    placeholder="2345464IG"
                    onValueChange={handleChange("employeeId")}
                    value={values.employeeId}
                    required={true}
                    error={touched.employeeId && errors.employeeId}
                  />
                  <BaseInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder="John"
                    onValueChange={handleChange("firstName")}
                    value={values.firstName}
                    required={true}
                    error={touched.firstName && errors.firstName}
                  />
                  <BaseInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder="Doe"
                    onValueChange={handleChange("lastName")}
                    value={values.lastName}
                    required={true}
                    error={touched.lastName && errors.lastName}
                  />
                  <div className="p-3 row">
                    <BaseButton
                      loading={loading}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update user information
                    </BaseButton>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
